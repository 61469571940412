type notesProps = {
    name: string
}

function APnoteCategories({ name }: notesProps) {
    return (
        <div className="apcSection">
            <h2>Notes</h2>
        </div>
    )
}

export default APnoteCategories