import './styles/css/Combined.css'

import 'react-toastify/dist/ReactToastify.css';

import NavBar from './components/NavBar/NavBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home'
import Contact from './components/pages/Contact'
import WebDev from './components/pages/WebDev'
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import Subpage from './components/pages/subpages/Subpage';
import { createContext, useEffect, useState } from 'react';
import WebdevMain from './components/pages/WebdevMain';
import GamedevMain from './components/pages/GamedevMain';
import GameDev from './components/pages/GameDev';
import ModelViewer from './components/3d/ModelViewer';
import ChangerLogin from './components/pages/ChangerLogin';
import Changer from './components/pages/subpages/Changer';
import instance from './components/network/axios';
import APcontent from './components/pages/adminPanel/APcontent';
import { ToastContainer, toast } from 'react-toastify';
import { cookieProps, errorType, langProps, overviewType, accountType } from './types/types';
import Cookies from './components/Info/Cookies';



export type cardStyleType = {
  [index: string]: {
    class?: string,
    color?: string,
    back?: string
  }
}

type overviewProps = {
  overview: overviewType,
  gameOverview: overviewType,
  error: errorType[]
}

type readyProps = {
  ready: boolean,
  setReady: React.Dispatch<React.SetStateAction<boolean>>
}

type accountProps = {
  account: accountType,
  setAccount: React.Dispatch<React.SetStateAction<accountType>>
}



export const LangContext = createContext<langProps>({} as langProps)
export const OverviewContext = createContext<overviewProps>({} as overviewProps)
export const ReadyContext = createContext<readyProps>({} as readyProps)
export const AccountContext = createContext<accountProps>({} as accountProps)
export const skillstyleContext = createContext<cardStyleType>({})
export const cookieContext = createContext<cookieProps>({} as cookieProps)




function App() {
  //console.log(instance)
  console.log("render app")
  const [contact, setContact] = useState(false)
  const [daten, setDaten] = useState(false)

  const [lang, setLang] = useState("eng")
  const [cookie, setCookie] = useState<boolean | undefined>(undefined)
  const [overview, setOverview] = useState<overviewType>([])
  const [gameOverview, setGameOverview] = useState<overviewType>([])
  const [cardStyle, setCardStyle] = useState<cardStyleType>({})
  const [error, setError] = useState<errorType[]>([] as errorType[])
  const [ready, setReady] = useState(false)
  const [account, setAccount] = useState<accountType>(undefined)

  //const { check } = useCheckJWT()


  function addError(msg: string, code?: string) {
    console.log("addError")
    setError(prev => {
      let errors = [...prev]
      errors.push({ "msg": msg, "code": code ?? "" })
      return errors
    })
  }



  useEffect(() => {

    document.documentElement.style.setProperty("--initialHeight", `${window.innerHeight}px`)

    window.addEventListener("resize", updateVPsize)
    function updateVPsize() {
      document.documentElement.style.setProperty("--viewportHeight", `${window.innerHeight}px`)
    }

    updateVPsize() //initialize

    //set or read language
    console.log("Browser lang: ", navigator.language)
    const language = localStorage.getItem("language")
    if (language) {
      setLang(language === "ger" ? "ger" : "eng")
    } else {
      switch (navigator.language) {
        case "de":
        case "de-DE":
          setLang("ger")
          localStorage.setItem("language", "ger")
          toast.info("Sprache auf Deutsch gesetzt", {
            autoClose: 3500
          })
          break;
        default:
          setLang("eng")
          localStorage.setItem("language", "eng")
          toast.info(`Initially set the language to english (your browser: ${navigator.language})`, {
            autoClose: 3500
          })
      }
    }

    instance.get("?type=style&name=skillcards", { headers: { "jwt": sessionStorage.getItem("jwt") } })
      .then(response => response.data)
      .then(result => setCardStyle(result))
    //.catch(error => addError(error.message, error.code))

    instance.get("?type=all_overview", { headers: { "jwt": sessionStorage.getItem("jwt") } })
      .then(response => response.data)
      .then(result => setOverview(result))
      .catch(error => addError(error.message, error.code))

    instance.get("?type=game_overview", { headers: { "jwt": sessionStorage.getItem("jwt") } })
      .then(response => response.data)
      .then(result => setGameOverview(result))
      .catch(error => addError(error.message, error.code))


    return (() => {
      window.removeEventListener("resize", updateVPsize)
      //sessionStorage.removeItem("jwt")
    })
  }, [])


  useEffect(() => {
    if (account) {
      const exp = account.exp
      const deleteAcc = () => {
        sessionStorage.removeItem("jwt")
        setAccount(undefined)
      }
      if (exp) {
        toast.success(lang === "eng" ? `Logged in as "${account.name}"!` : `Erfolgreich als "${account.name}" eingeloggt!`)
        const now = Math.floor(new Date().getTime() / 1000)
        setTimeout(() => {
          console.info("This session has timed out.")
          deleteAcc()
        }, (exp - now) * 1000);
      } else {
        console.warn("There is no 'exp' property on the account object. The JWT will be deleted, as it may not be valid.")
        deleteAcc()
      }
    }
  }, [account])


  useEffect(() => {
    /* if (error.msg) toast.warn(`${error.msg}`) */
    console.error(error)
  }, [error])


  return (
    <Router>
      <AccountContext.Provider value={{ account, setAccount }}>
        <LangContext.Provider value={{ lang, setLang }}>
          <OverviewContext.Provider value={{ overview, error, gameOverview }}>
            <ReadyContext.Provider value={{ ready, setReady }}>
              <skillstyleContext.Provider value={cardStyle} >
                <cookieContext.Provider value={{ cookie, setCookie }}>
                  <Cookies />
                  <NavBar contact={contact} setContact={setContact} daten={daten} setDaten={setDaten} />
                  <Routes>
                    <Route path='/' element={<Home appError={error} />} />
                    <Route path='/contact' element={<Contact />} />

                    <Route path='/webdev' element={<WebDev />}>
                      <Route index element={<WebdevMain />}></Route>
                      {overview &&
                        <>
                          {overview.map(project => <Route path={project.link.replaceAll("webdev/", "")} element={<Subpage table='projects' index={project.id} />} />)}
                        </>
                      }
                    </Route>

                    <Route path='/gamedev' element={<GameDev />}>
                      <Route index element={<GamedevMain />}></Route>
                      {gameOverview &&
                        <>
                          {gameOverview.map(project => <Route path={project.link.replaceAll("gamedev/", "")} element={<Subpage table='gameProjs' index={project.id} />} />)}
                        </>
                      }
                    </Route>

                    <Route path="/modelviewer" element={<ModelViewer />}></Route>

                    <Route path="/changer">
                      <Route index element={<ChangerLogin />}></Route>
                      <Route path="loggedin" element={<Changer />}>
                        <Route path=":content_id" element={<APcontent />} />
                      </Route>
                    </Route>

                    <Route path='*' element={<NotFound />}></Route>
                  </Routes>
                  <Footer setContact={setContact} setDaten={setDaten} />
                </cookieContext.Provider>
              </skillstyleContext.Provider>
            </ReadyContext.Provider>
          </OverviewContext.Provider>
        </LangContext.Provider>
      </AccountContext.Provider>
      <ToastContainer position='top-right' />
    </Router>
  );
}

export default App;
