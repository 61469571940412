import React, { useContext, useEffect, useRef, useState } from 'react'
import emailjs from 'emailjs-com'
//import '/styles/css/Contact.css'
//import '/styles/css/Loading.css'
import $ from 'jquery'
import { LangContext, cookieContext } from '../../App'
import { toast } from 'react-toastify';
import Loading from '../helper/Loading';
import '../../styles/css/Contact.css'
import FriendlyCaptcha from '../helper/FriendlyCaptcha';
import { captchaVerify } from '../../types/types';
import CookieMsg from '../Info/CookieMsg';
import instance from '../network/axios';
import { useParams, useSearchParams } from 'react-router-dom';


function Contact() {
  const form = useRef<HTMLFormElement>(null!);
  const names = ["Omar", "Mohamed", "Imene", "Fatma", "Noah", "Mateo", "Emma", "Amelia", "Celeste", "Luke", "David", "Alexander", "Laia", "Eliška", "Sofia"]
  const mails = ["gmail.com", "protonmail.com", "web.de", "gmx.de", "company.de", "aol.com", "outlook.com", "yahoo.com"]
  const { lang } = useContext(LangContext)
  const { cookie } = useContext(cookieContext)
  const [searchParams, setSearchParams] = useSearchParams()

  const [msgLength, setMsgLength] = useState(0)
  const [btnClass, setBtnClass] = useState("")
  const [inputs, setInputs] = useState([false, false, false])
  const [valid, setValid] = useState<captchaVerify>("verify")
  const [mail, setMail] = useState<string>("")
  const [name, setName] = useState<string>("")
  const [subject, setSubject] = useState<string>("")
  const [message, setMessage] = useState<string>("")

  const [sendIP, setSendIP] = useState(false)
  const [sendCopy, setSendCopy] = useState(false)

  const [myIP, setMyIP] = useState<string | undefined>(undefined)


  function title() {
    document.title = lang === "eng" ? "Contact" : "Kontakt"
  }


  useEffect(() => {
    const rndName = names[Math.floor(Math.random() * (names.length - 1))]
    const rndMail = mails[Math.floor(Math.random() * (mails.length - 1))]
    $("#in1").prop({ placeholder: rndName })
    $("#in2").prop({ placeholder: `${rndName.toLowerCase()}@${rndMail}` })
    //console.log(rndName, rndMail)
    const metaIcon: HTMLLinkElement = document.getElementById("icon") as HTMLLinkElement
    if (metaIcon) {
      metaIcon.href = "/images/favicon.ico"
    }
    title()
    if (searchParams.get("ip_checkbox") === "true") {
      setSendCopy(true)
      setSendIP(true)
      setMessage("Hallo, ich möchte, dass meine Daten entfernt werden.\r\nHello, I would like you to remove my data.")
      setSubject("Datenlöschung | Data removal")
      instance.get("?type=getIP")
        .then(response => response.data)
        .then(result => {
          setMyIP(result as string)
        })
    }
  }, [])

  useEffect(() => {
    title()
  }, [lang])

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    console.log("clicked on send mail")
    if (valid === "valid") {
      e.preventDefault();
      setBtnClass("loading")

      //check if ids are there and the fields are actually filled in with something. This is to prevent client side manipulation of the html
      for (let i = 0; i < 3; i++) {
        if (!document.getElementById(`in${i + 1}`) || (document.getElementById(`in${i + 1}`) as HTMLInputElement).value === "") {
          window.location.reload();
          break;
        };

        //Try to send message on last interation
        if (i === 2) {
          const msg = sendIP ? message + `\n\n[IP: ${myIP}]` : message

          if (sendCopy) {
            instance.post("?type=sendMail", {}, { headers: {
              type: "contactForm__copy",
              recipient: `${mail}`,
              recipientName: `${name}`,
              subject: `${lang === "eng" ? "Copy from the message i sent on jfgoldbach.de" : "Kopie der Nachricht, die ich auf jfgoldbach.de gesendet habe"}`,
              message: JSON.stringify(msg)
            } })
              .then(respone => respone.data)
              .then(result => {
                console.log("copy result", result)
                if (result.success) {
                  toast.success(lang === "eng" ? "Copy sent!" : "Kopie gesendet!")
                  form.current.reset()
                  setMsgLength(0)
                } else {
                  toast.warn(lang === "eng" ? "Couldn't send Copy." : "Kopie konnte nicht versendet werden.")
                }
                setBtnClass("done")
              })
              .catch(error => {
                console.warn("error")
                toast.warn(error)
                setBtnClass("error")
              })
          }

          instance.post("?type=sendMail", {}, { headers: {
            type: "contactForm",
            subject: subject,
            message: JSON.stringify(msg)
          } })
            .then(respone => respone.data)
            .then(result => {
              if (result.success) {
                toast.success(lang === "eng" ? "Message sent!" : "Nachricht gesendet!")
                form.current.reset()
                setMsgLength(0)
              } else {
                toast.warn(lang === "eng" ? "Couldn't send message." : "Nachricht konnte nicht versendet werden.")
              }
              setBtnClass("done")
            })
            .catch(error => {
              console.warn("error")
              toast.warn(error)
              setBtnClass("error")
            })
        }
      }
    }
  };

  useEffect(() => {
    //console.log(inputs)
  }, [inputs])

  const messageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
    //console.log(e.target.value)
    setMsgLength(e.target.value.length)
    setInputs(prev => [prev[0], prev[1], e.target.value.length > 2])
  }

  const nameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputs(prev => [e.target.value !== "", prev[1], prev[2]])
    setName(e.target.value)
  }

  const mailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputs(prev => [prev[0], e.target.value !== "", prev[2]])
    setMail(e.target.value)
  }

  const subChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value)
  }

  const btnStatus = (valid === "verify") ?
    "verify"
    :
    (valid === "invalid") ?
      "invalid"
      :
      (inputs.filter(res => res === false).length !== 0) ?
        "inactive"
        :
        ""

  function ipCheck(e: React.ChangeEvent<HTMLInputElement>) {
    setSendIP(e.target.checked)
  }

  function copyCheck(e: React.ChangeEvent<HTMLInputElement>) {
    setSendCopy(e.target.checked)
  }



  return (
    <div className='contact-container'>
      <div className='main-element contact-background scaleIn'>

        <h1>
          <i className="fa-solid fa-envelope" />
          {lang === "eng" ? "Write me a message!" : "Schreib mir eine Nachricht!"}
        </h1>

        <CookieMsg />

        <form onSubmit={sendEmail} ref={form} className={`${cookie ? "" : "unavailBlur"}`}>
          <label title='Name required'>
            <p>Name:</p>
            <input id='in1' required name='from_name' onChange={nameChange}></input>
          </label>
          <label title='Email required'>
            <p>Email:</p>
            <input id='in2' required type='email' name='from_email' onChange={mailChange}></input>
          </label>
          <label>
            <p>{lang === "eng" ? "Subject:" : "Betreff:"}</p>
            {/* <small>optional</small> */}
            <input name='subject' placeholder='Optional' onChange={subChange} value={subject}></input>
          </label>
          <label className='message' title='Message required'>
            {/* <p>{lang === "eng" ? "Message" : "Nachricht"}</p> */}
            <textarea
              id='in3'
              required
              minLength={3}
              maxLength={1750}
              spellCheck
              name='message'
              value={message}
              onChange={messageChange}
              placeholder={lang === "eng" ? "Your message here" : "Nachricht hier schreiben"}
            />
            <p className="charInfo">
              <span className={msgLength >= 1500 ? "tooLong" : ""}>{msgLength}</span>
              /1500
            </p>
          </label>

          <div className='bottom-container'>
            {cookie && <FriendlyCaptcha setValid={setValid} />}

            <button type="submit" className={`contactBtn ${btnClass} ${btnStatus}`}>
              <div id='send' className='submit-send' title={lang === "eng" ? "Send" : "Senden"} >
                <i className="fa-solid fa-paper-plane" />
              </div>
              {btnClass === "loading" &&
                <Loading light />
              }
              {valid === "verify" &&
                <Loading />
              }
            </button>
          </div>
          {searchParams.get("ip_checkbox") === "true" &&
            <label className="checkbox"
              title={lang === "eng" ?
                "With this information, your data can be removed more easily."
                : "Mit dieser Informationen können die Daten leichter entfernt werden."}
            >
              <p>
                {lang === "eng" ? "Send my IP adress" : "Meine IP Adresse mitsenden"}
                <span className="ip">{(myIP === "" || myIP === undefined) ? <Loading small /> : myIP}</span>
                <i className="fa-solid fa-circle-info" />
              </p>
              <input type="checkbox" checked={sendIP} onChange={ipCheck}></input>
            </label>
          }
          <label className="checkbox">
            <p>{lang === "eng" ? "Send a copy to my email" : "Kopie an meine Email"}</p>
            <input type="checkbox" checked={sendCopy} onChange={copyCheck}></input>
          </label>
        </form>

      </div>
    </div>
  )
}

export default Contact